var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',{staticClass:"auth-pages bg-default-login",style:({
      backgroundImage: _vm.logoFooterResourceName !== '' ? ("url('" + _vm.bgFooter + "')") : undefined,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom',
      backgroundAttachment: 'fixed',
      backgroundSize: '100%',
      height: '100vh'
    })},[_c('v-container',{staticClass:"pb-0",staticStyle:{"margin-top":"60px","max-width":"100%"}},[_c('v-row',{staticClass:"d-flex justify-center mt-n16"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"8","xl":"5"}},[_c('v-card',{staticClass:"card-login",attrs:{"color":"rgb(255, 255, 255, 1)"}},[_c('v-card-title',{staticClass:"justify-center"},[(_vm.logoResourceName !== '' )?_c('v-img',{staticClass:"logo-image-background-size",attrs:{"src":_vm.logoSrc,"max-width":"300","width":"90%"}}):_vm._e()],1),_c('v-stepper',{staticStyle:{"box-shadow":"none"},model:{value:(_vm.auth.step),callback:function ($$v) {_vm.$set(_vm.auth, "step", $$v)},expression:"auth.step"}},[_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('login-form',{attrs:{"auth":_vm.auth}})],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('login-two-factor',{attrs:{"auth":_vm.auth}})],1)],1)],1),_c('v-card-text',{staticClass:"text-center py-0 my-0"},[_c('v-btn',{staticClass:"theme-link-button-emphasis fw300",attrs:{"text":"","small":"","rounded":"","elevation":"0"},on:{"click":_vm.showForgotPasswordDialog}},[_vm._v(" "+_vm._s(_vm.$t('¿Ha olvidado su contraseña?'))+" ")]),_c('v-btn',{staticClass:"theme-link-button-emphasis fw300",attrs:{"text":"","small":"","rounded":"","elevation":"0"},on:{"click":_vm.goToRegister}},[_vm._v(" "+_vm._s(_vm.$t('Registrarme'))+" ")]),_c('br'),_c('br'),_vm._l((_vm.languages),function(language){return _c('span',{key:language.iso},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"img-flag-login",staticStyle:{"cursor":"pointer"},attrs:{"id":'btn-language-' + language.iso,"src":_vm.setIsoFlag(language.iso),"alt":""},on:{"click":function($event){return _vm.changeLanguage(language.iso)}}},'img',attrs,false),on))]}}],null,true)},[_vm._v(" "+_vm._s(language.tooltip)+" ")])],1)})],2)],1)],1)],1),_c('v-dialog',{attrs:{"width":"100%","max-width":"500px"},model:{value:(_vm.forgotPasswordDialog),callback:function ($$v) {_vm.forgotPasswordDialog=$$v},expression:"forgotPasswordDialog"}},[_c('validation-observer',{ref:"recoverPassword",scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('v-card',{staticClass:"nocard "},[_c('div',{staticClass:"  gradient-text-color "},[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('span',{staticStyle:{"color":"#ffffff !important","font-weight":"300","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.$t('Recupera tu contraseña'))+" ")]),_c('v-icon',{staticClass:"float-right",staticStyle:{"margin-top":"6px","margin-right":"6px"},attrs:{"color":"#ffffff"},on:{"click":function($event){_vm.forgotPasswordDialog = false}}},[_vm._v(" fas fa-times-circle ")])],1)],1)],1)],1),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('Ingresa los datos para proceder a la recuperación de su contraseña'))+" "),(_vm.showSuccessSendLink)?_c('v-alert',{staticClass:"mt-4",attrs:{"color":"green","type":"info"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Solicitud enviada con éxito') + '. ' + _vm.$t('Si los datos son correctos, te enviaremos las instrucciones para restablecer tu contraseña a la dirección de correo indicada') + '.')+" ")])]):_vm._e(),_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","type":"email","error-messages":errors},model:{value:(_vm.recoverPasswordData.email),callback:function ($$v) {_vm.$set(_vm.recoverPasswordData, "email", $$v)},expression:"recoverPasswordData.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Usuario'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('Usuario'),"type":"text","error-messages":errors},model:{value:(_vm.recoverPasswordData.username),callback:function ($$v) {_vm.$set(_vm.recoverPasswordData, "username", $$v)},expression:"recoverPasswordData.username"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"theme-secondary-btn",attrs:{"dark":!invalid,"block":"","elevation":"0","disabled":invalid,"loading":_vm.loadRecover},on:{"click":_vm.sendLink}},[_vm._v(" "+_vm._s(_vm.$t('Enviar petición'))+" ")])],1)],1)]}}])})],1)],1)],1),_c('alert')],1)}
var staticRenderFns = []

export { render, staticRenderFns }